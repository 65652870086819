// $lightblue: #86cee1;
$pink: #d794c4;
$blue: #8ec4d4;
$yellow: #dac600;
$red: #a51c18;
$orange: #d7800f;

$lightblue: #8ec4d4;
$bgcolor: #18213f;


@import './icons.scss';

@keyframes show {
	0% {
		opacity: 0;
		transform: scale(2);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
	position: relative;
}

html, body {
	width: 100%;
	height: 100%;
}

html {
	font-size: 14px;
}

body {
	margin: 0 0 15rem 0;
	overflow-x: hidden;
	background-color: #fff;
}

h1, h2, h3 {
	text-align: center;
	font-weight: 400;
	font-family: 'New Porto';
	margin-bottom: 2.5rem;

}

a {
	text-decoration: none;
	color: inherit;
	transition: color 0.2s ease;
	&:hover {
		color: #000;
	}
}


.loader {
	font-size: 10px;
	position: absolute;
	text-indent: -9999em;
	border-top: 5px solid rgba(0, 0, 0, 0.2);
	border-right: 5px solid rgba(0, 0, 0, 0.2);
	border-bottom: 5px solid rgba(0, 0, 0, 0.2);
	border-left: 5px solid #000;
	top: 50%;
	left: 50%;
	animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
	border-radius: 50%;
	width: 50px;
	height: 50px;
}
@keyframes load8 {
	0% {
		transform:  translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform:  translate3d(-50%, -50%, 0) rotate(360deg);
	}
}

.loaded .loader {
	animation: none;
	display: none;
}

.capa {
	width: 100%;
	height: 100%;
	background: url(/img/capa-large.jpg) no-repeat;
	background-size: 50% auto;
	background-position: center;
	background-color: #fff;
	margin: 0 auto;
	opacity: 0;
	box-sizing: content-box;
}
.loaded .capa {
	animation: show 0.5s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
}

.data-passada {
	text-decoration: line-through;
}

@keyframes upDown {

	0%, 100%  {
		transform: translateY(-10%);
	}
	50%  {
		transform: translateY(10%);
	}

}

#picker {
	top: 10px;
	left: 0;
	position: absolute;
	width: 100%;
	height: 20px;
	text-align: center;
	transform: translateY(-200%);
	i {
		cursor: pointer;
		display: inline-block;
		margin: 0 0.25em;
		border: 1px solid black;
		width: 20px;
		height: 20px;
		opacity: 0.6;
		&.current {
			opacity: 1;
		}
	}
	animation: showPicker 0.5s 5s forwards;
}

@keyframes showPicker {
	100% {
		transform: translateY(0);
	}
}



.scroll-down-please {
	width: 40px;
	height: 40px;
	position: absolute;
	bottom: 20px;
	left: calc(50% - 20px);
	transform: translateY(-10%);
	animation: upDown 0.8s ease infinite;
	&:before,
	&:after {
		content: ' ';
		position: absolute;
		width: 45%;
		height: 2px;
		background-color: #000;
		bottom: 25%;
		left: 50%;
		transform-origin: 0 50%;
		transform: rotate(-45deg);
	}
	&:after {
		transform-origin: 0 50%;
		transform: rotate(-135deg);
	}

}

.social-horizontal,
.social {
	position: fixed;
	top: 10px;
	left: 10px;
	z-index: 1;
	transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
	opacity: 0;
	transform: translateX(-50%);
}

.social-horizontal {
	position: relative;
	top: initial;
	left: initial;
	transform: none;
	width: 100%;
	transition: none;
	opacity: 1;

	.social-icon {
		display: inline-block;
		margin: 0.25em;
	}
}

.loaded .social {
	transform: translateX(0);
	opacity: 1;
}


@media all and (max-width: 1024px) {
	#picker {
		display: none;
	}
}

@media all and (orientation: portrait) {
	nav.social {
		background-color: rgba(255,255,255,0.5);
		width: 100%;
		left: 0;
		top: 0;
		text-align: center;
		height: 44px;
		a {
			display: inline-block;
			margin: 0.5em;
		}
	}
}


.pagseguro,
.paypal {
	display: inline-block;
	margin: 1rem 4rem;
	form,
	input {
		margin: 0;
		padding: 0;
		line-height: 1;
	}
}

.btn {
	color: #fff;
	text-decoration: none;
	padding: 0.3rem 1rem;
	display: inline-block;
	margin: 3rem;
	margin-bottom: 0;
	transition: color 0.2s ease, border 0.2s ease;
	&:before {
		content: ' ';
		border: 2px solid #fff;
		transition: all 0.2s ease;
		position: absolute;
		top: 0;
		left:0;
		right:0;
		bottom: 0;
	}
	&:hover {
		color: $lightblue;
		&:before {
			border: 2px solid $lightblue;
			top: -0.25rem;
			left:-0.33rem;
			right:-0.33rem;
			bottom:-0.25rem;
		}
	}
}


.container {
	color: #fff;
	max-width: 100%;
	width: 100%;
	> section {
		min-height: 30rem;
		padding: 10rem 0;
		text-align: center;

		h1 {
			color: inherit;
			font-size: 4rem;
		}

		h2 {
			font-size: 2.5rem;
			color: $lightblue;
		}

		section, ul {
			width: 100%;
			display: block;
			list-style: none;
		}

		section {
			padding: 3rem 0;
		}

		&:first-child {
			background-color: $bgcolor;
			color: #fff;
		}
		&:nth-child(2) {
			background-color: mix($bgcolor, $lightblue, 70%);
			color: #fff;
		}
		&:nth-child(3) {
			background-color: mix($bgcolor, $lightblue, 50%);
			color: #fff;
		}
		&:nth-child(4) {
			background-color: mix($bgcolor, $lightblue, 30%);
			color: #fff;
		}
		&:last-child {
			background-color: $lightblue;
			color: #fff;
		}

		// > *:last-child {
		// 	margin-bottom: 0;
		// 	padding-bottom: 0;
		// }

	}
}


.video {
	display: block;
	max-width: 900px;
	max-height: 506px;
	width: 76vw;
	height: 42.7vw;
	margin: auto;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #CCC;
	.play {
		height: 72px;
		width: 72px;
		left: 50%;
		top: 50%;
		margin-left: -36px;
		margin-top: -36px;
		position: absolute;
		background: url("//i.imgur.com/TxzC70f.png") no-repeat;
		cursor: pointer;
	}
}

@media all and (max-width: 1336px) {
	html {
		font-size: 12px;
	}
}

@media all and (max-width: 768px) {

	p {
		width: 90%;
		margin: auto;
	}

	html {
		font-size: 10px;
	}

	.video {
		width: 85vw;
		height: 47.81vw;
		margin: 2rem auto;
	}

}

@media all and (max-width: 480px) {
	html {
		font-size: 9px;
	}
	p {
		width: 90%;
		margin: auto;
	}
	.container {
		padding: 0;
	}
}

@media all and (max-width: 320px) {
	html {
		font-size: 7px;
	}
	.container {
		padding: 0;
	}
}

@media all and (orientation: portrait) {
	.capa {
		background-size: 100% auto;
	}
}

@media screen and (min-aspect-ratio: 5/2) {
	.capa {
		height: 200%;
		background-position: center top;
	}
}

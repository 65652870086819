.social-icon {
	width: 28px;
	height: 28px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: block;
	margin: 7px 0;
	opacity: 0.75;
	transition: opacity 0.2s ease;
	&:first-child {
		margin-top: 0;
	}
	&:hover {
		opacity: 1;
	}
}

.social-icon--playstore {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACL0lEQVRYR8XXy+sNcRjH8devlB3+AxYsLPwDtpStksvS5R/AxjW55JJEERuR3IoSUm4rG8lCJIVYSJRcIvc7PTVT36YzZ75z5px+3805zUzfz3ue5/M8z3fGjPMa66E/AbPwBi9GzVcFmIQvOIXFOI8NeDgqkBQg/j/C9gKghPiDY9g8ioikAFPxDH+xrAIRAfiOA9iJ98OKSAowHU+Kjesg4vYH7MJ+fOsKUgcQ+/aDiPsvsQVHEWkaaPUDyIGIZx4XRj03CEETQC5EPHcba3GjDUgOQBuIePYq1uFeDkguQFuIO5iNf/jVD6QNQC7EXczFDFzCGWzD614gbQGaIFLxa5hciH7GXuzBpxRkEIA6iMj5nOLNU/FUL+bLUlwuLw4KUIWIfrAP0czqxEvNmDXT8DYudAEoIRbgAmbiVhL2ft6bj4vDAIiwh+Gia0ZuT2NhRvlFhQRspwiU4hH2K1iNkxkQ1zGvqwdS8TLnEYUVBUSM8kWVSHzE7qISfnQB6Of2gFiejPKACLGD2IF31fS0NWFOqaVTdBXO4nmdL9oA5IiXOnGUW4KfTYbMBajrcNX9b2IN4jdr5QDkiD/A+qL3ZwnnmrBJPHK7CSeKXtBKvKkP9BMPN8fp+VDh8tbCTRGoE/9a9Pyo56jrziv1wJRiQNxP5nnZZH7jMLbiVWfVZIPql9FKHE9GanwpRR1vxNNhCvdKQXltIo4UNRwdLI5XI1u9Pk5HJtZr43EH+A+eT7IhKoNMRgAAAABJRU5ErkJggg==');
}
.social-icon--facebook {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACnklEQVRYR8XXS6hVVRjA8d/13UDxgUoqiUiCoJjkIxUDESHCECPIwEJQpw4MB9pAJz4GYoSzIkEFSVAKw4HgqNDUShDRtAY5MAnSBEEy05Tvsjbsuztn73XuseuCA4ezvsd/rfOt79HjOa+e5+xfNwBDMCod4B4e9ecwnQCMwCq8hdcwDYOS08f4FedwAl/jQQ5QDkA43pw+43KM4g/sw8f4u06nCWABjmB6puOq2C94Dz+2068DeBeHMKyfzgu1uIG1ONbKTjuAd3C09B93ySBiJGx+VTXUCmA2zuOFBq//YH8yehtPMBIX2ujdx3z8VN6vAgzGD3gl48jrcaAiNxp3a3TD9kL8W8hUATbgswznN/FSOnVZvAkgZD/A4XYA1zEjA+AU3ijJ7cUaehPbpAb9K5jVCiCSy3cZzkPkJFaWZE9jeaZuiL2Ki/Gl/BfswPYMI3H6CNKy7G7MTa9mRYaNj7CrChCnejNDuS53vIhbGTa+xNtVgKuYmaFcBxBXG5HetC4VL61sLCJ7cpMmluEOLpdk52BMemJ7MmzcSMWsTwzEj1MzlJ9FEEaN6H1t5Rv4HvMGCOAsllQBovC8P0AAnyOSXp8b2IhPBwhgHQ5WASbiN0Q9aFrdJKIoYvFcI5D/0xMeL95nA0E3AF+kJqXXRfVNRxWM7qXo9dpx9BcgGtfwEfWgJUD8+Ak2/U83EEVrS9l2q6w2HGdSwWjH8XuSKfaXYkIDdNSP1/GwCSD2IyC/ySzNTQEb+9eS8+iW+6y6vD4+tVuLczzUyHyL1UXUdwIQsjH9bMNWxHzQyfoLOxG1IZrSlqtpLiiUpuDDlCmbhpO45mi5YjCJvFK7cgEKI0PTWLYIL2Ns2vgTP6eOKsaz7DmxU4CmA3W8/xTCl3kh7378YwAAAABJRU5ErkJggg==');
}
.social-icon--youtube {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADZklEQVRYR8XXaeimUxgG8N/YJcrW2IWQwWTse5QtGTOkhrKlmQ98kCWyJEuRJMsH2fIBEaKZDF+Ukn0vZP9AWVIiZN+7pvPo+Z+e93mf9x01d739n//Z7uucc9/XdZ9ZVrHNWsX+rQyANbBB2cAP+HOazUwCYB0swHzsj+2wWnH6Fz7By3gcy/HrEEBDAMTxBeW38ZBF8TVuws34rW/OOAD74kHsMNBxPexjnII3Rs3vA7AI92GtKZ0303ICp+LRrnVGATgJD7fueCUxSIxkzWX1Ql0AdscrWLcanCjfGc/hUhxZdjYU3E/YB++3J9QAVsfr2GPEqqfjcFyCd/EOfsfR5TuZ8TYOHDE/a++Hv5v+GsBi3N2zpaRXnG+J83Ex1sO5uB234GD8iGymy7KJ+0cB+BA79QBIQM3GDVgbz2AzbFLudwiAnNxuXQBCLi8NuNB5uB5fTQkgLvbCm/loX8FVuHICAN/jHmyIQ/BQCcxrCvmMuoK4uBzX1QCexLEDANyGo7ANri6UG+CZH/r9DFeMSeGlOLEG8B52GQDg/xjyVpNp7Sv4vER37SA7OgdP448JvCeYc8wRsNo+LWI2IwbSuG01Mg7n4gO8ik3L/e6Jn8vYsxE5fqDDUeIgxHVA1ReNWJFt7RN4DXtXA7PrI1pt35W0m4MvC7PtiqjkU8XZGYWkni/zzirB2l76RRxUA4jwnFYBiB6c3AHgRjyBO8ovDLiwyO/WOB75Gzuu1AftpZM9Ib0ZJ7AEd00JICcThnwEh2ELbNUD4EzcWwMIw31RUejQE2gAZHz0Yf1CzfGRCipVUmOJq83xTQ0g/z/W5GcZnbhIUdJY2C+BmjwPc+b7ThyD7XFhIbMEaCNI55WradYIYaVIWWG1GEUFU700tV7G5D4jQrVl7j+txsyJyqVQjRjle6NCuU12RdLjI3rQCSCNtxZ1a8ZEbtOWjOit71pgAi61w0XlZJquBG/a/rOugiQq90IRjI6NT92UIufQUj/0AkhnAvLZMdI8CZIQWZynWp5hfUVpWC813KjqZiiAMOEJTdRPAiBj8/q5rNSAeR9MYr/g2lI7pCjttHHvgmZSSCUpFqYc9zjJMafkysMkvNJrQwE0i6xZ8j/ismNJs/R9i49KRZXn2eB34qQAxm1o4v5/AU33sSGJLnPTAAAAAElFTkSuQmCC');
}
.social-icon--itunes {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAjpJREFUeNpiZCAdGAOxKpQWhop9AeKTQHwfiI8x0AAIAnEBEB8G4v8E8BkgbgBiBWpZDjLsKxEWY8NTgZiDXIvVgfgsmRYj4ydA7Eaq5Y5UsBgdxxNruS0NLIfhBEKWy1DJogdAfAOHnB2yhYxoDrgExLpEhtQfaLa7BrXwKRCfBuLnQHwT6tv5WPSBsiw/EP9Dlygl0ndRQKwNxEIEHFiCx4zl6Io5SQheViJDqISAOaDCjIEJqjiVhBxCrQIGVLAxsEA5KSRoZEJiswOxDhArArEJNGp+AXEwlMYHQGmkCeQAfRISHgM0sYkA8RZoMKKnhbdQ+h8Bc7iA2AbkAC0Sgw6UiiWA2ByH/AscOQwbUGNCz5dEAlEqpQMVkAP4yND4h0oOYGUiIq5oCf6BHPCTyobCsul/ItT+BzngCBWj4CIQL4aymYkw5z4LtNwmBnwA4itQNjeaHKgVlAvEJ5DE2Igw8x4DtGi9iafIBBUo6dDiGgaCkOQf4zA8mUBR/B2I5WGK6/AodMdiuAOS/FIcDlhBwAGrkRVL4FD0BYfhiUhqrmORzyaiUrNG1zQRi6LfWEpKUIvpE5o6UOOjGIqPEmH5QVwNkjdIbX0Y+AbEM6A0qOIJoEJWFQfiV9gkzGjYHoThaEKui6Wh5a3EBlESDSxvIjWevKDxRA3L08lNLKCachEFFu8EVbnUqGCMoP28l0RY+gna6nUixmBGEh0iCW0JqUKLUS6oOKi4fggt20/DynhiAECAAQCKGYVzY9Tk1AAAAABJRU5ErkJggg==');
	background-size: 90% 90%;
}
.social-icon--instagram {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADWklEQVRYR8XXWciuUxQH8N8xZD45KBmSIYpMociUCxkyS4ZOIkURGS9IicKFG1wQcUEZQiI658iNeRYZM2UoIRfmee7/2c/peffZ7/e873e+OutyP2vt/V/Tf61ngVUsC1bx+1YGwGpYvzjwE/6ZizPTAFgbR+NI7INtsEZ59E98hBfwCJYiZ4MyCYA8fBEuxCaDN/6v8CWuw01DQIYA7I27sd2ED9dq7+AUvDXOfjYAMbwTa87x8c7sV5yER1v3jANwIu5FCm0+5K9SP8vqy1oAdsOLSO7nU37EHqVYl99bA0hVv4ZdGi//XCr8PfwxBtla2AuHY/WGznM4AP9232oAZ+HWhuFLOBbfYNte+9WqAfkJ9iw536xxV+rh/haAgPmwUfE/YPuC/HZsOJCXN3E8NsfTDd1EOABnpB+B/fFMwyCPhgfS2+vN8vjf2BGH4CgcVtpv54ZNztKiIwCuxuUN5QvwCpK/Tt7HFXi2gDqu2J6B/XA21sF9SEfVEoeurwE8hkMbyrkshfdE+fYu9sVWhWS+RaKU2gigrnsS3buwuHHnPd15PwUflFzX+jWAzIPPC++n6iNvl+q/Bhf30jsOQGZGnBhJwVfYdIIILMS1OLfSTfTSxksmABDAM63ej0C82mICABvjMlxS6R6IjfDwBABeL6Q0AiCHu08A4DQ8hXBDF7HUzxG4DSnEzrlxKXi8q7d+BML9J08AIKlK/r4vj4acOgAP9ebHbEV4I9JdIxE4p8zvGsN5eLl43H1L5eeStOa6hSUTmW54ZQLm/AGc0HAqZw/WALbEZ40JeAvOx6doUWvj/pmt6Bi0OuuXkrqscSvshDEMi/XlO+yArUu/79p6sZxlL0x+Ty9023VE3ySccWZ3UA+jTLKEuz4PE4bf0ykboOv//sWZcBlGvyG0nm5Ix/Tld+yEj8cByPnNhUprR3Nxii00nAWjJcl7Bk1GbmvXuBJX9Q1bSuHwMFUWk/mUJ3EwMrSWy7iVLMWWyTjXZbQG/gYOQuppRGZbSkMyyWP+AVZGUpSZiOGNFWRoLQ+3Z0RfOocdMW2WkX1DfwWbJgJ93XBEZvipE/ycfIE7ClF9PRS6oQjU9olIflaSlqxpi4p3oeOQzvN4dZr/xGkBDDk09ff/ALlNrCEiJmqtAAAAAElFTkSuQmCC');
}
.social-icon--spotify {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADMklEQVRYR8XXWcivUxQG8N9BhhA5DplPxuiQhMiYTBnDSYkrQ8oYhaLckAvKDeEOGVJS4hgSmSnzlHnOkHnMkLFH+9U++/zf732/c/511t33fmuv/ay1nmet/Z9jOduc5Xy/2QLYBIdhN2yFtUsC3+FtPI178OnYxMYC2AcX4gAGQf+N+3EZnhwCMgRgHVyLY4cC9fz/ZpyBH/rOzwRgQSnnpkt5eXfsfRyCtybF6QOwPR5BKjAN+xp74c022CQA6+JFbDyNm6sYH2AnfF/HnQTgDhw95cu7cLfi+JkAhOUPNJf/WCoSmX2CSO634rNqadNG2BI7YO4A+L3xeOfTViB9j+Rir+EUPINIa6xlPuxfqrgfVmgOJsGDJgFIBu9Uzmfi6rG39vhthnNxKlYpPv9gPj7O33UFzsGVVaAncGJx3gORZSSZEq9W/H7BNyVYGP5cKe+3DaBt8QLSstjpuKYFcDsWLmPGOZ52ZQLeiFsqvkQFyTyW7ye0AF4uJGoxJLPH8BIyVKLp37Fi2QUhYDLcBXti9SrAZ6Wqm+O06vuz2LUFkJ5k2dR2CS6eRVVWxoE4CUdMIGAX6r2imsU4MAnA+bgCGU5BvDXWL738o0jyQ7yK1xu1bIPLC5A2h1Ryi7YCryAjuLb086PSu6HFFeLdh5vKLAnbY9kBAV7b89i5BTA0AX9FiPQFwv7oey1EauFBbZkhWd8ZXA8Xv/r/t+G4FsB5pWRtBSKZh/AuuqzakmZp7VuGzzGV3ProcxauagGEyeljbT9jPfyJ7QpxNihM/wspewgVjf9UDs7DBTgbK/UgSP/DgyVeN3lS5blV21dYcyCrgHkKNxSNR6bJMI+R1h4t1frve0usw3FXX91Gfg9pr8PJHdObc1l4D3bfJjE7TD545GWzdbsTR9WHJgFIjzP10vtpWqbijkhL/7c+bWfohPlrTAlB3hRZ80lsMZtpuATEIoTVy2Kf49DyqFkiztB02xDXl/m+NCCSQPbCl32HhwB0547ERWXjjQESOV+Ke4ecxwLo4mRX5KfZ7uWnWSZgpmMGUl5TeQfcjTeGLp5JhmPPTsVvthWYyqV1kH8B/SaaIVs2QMEAAAAASUVORK5CYII=');
}

.social-icon--deezer {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAI9JREFUeNrsk9EJgDAMRFvpIG7SupmdTN3ETWr641druRCMQh7kJ3AkXC7OGYahjH9xVtFeIEkusHX6C6oJzAUOKc301xCKncD3wkHsaKCIk2oG+vd7tGr0Uq1awX4xB+obZkYIM+ha180wsDmBp4GpC8SHe0agz9J44UCJhhAOFFOjj6oDn8iAYRiqXAIMAMaMiXFw3bypAAAAAElFTkSuQmCC');
}